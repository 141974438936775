import React from 'react';
import _ from 'lodash';
import BaseLayout from '../components/Layouts/BaseLayout';
import CarouselSlideshow from '../components/Carousel/Carousel';
import { Container } from 'reactstrap';
import Markdown from '../components/Markdown/Markdown';

const PieceTemplate = ({ pageContext: { piece }, location }) => {
  const carouselItems = piece.images?.map(i => ({
    id: i.src,
    src: i.src,
    altText: `Image of Justin Scroggins' work titled '${piece.title}'`,
  }));

  return (
    <BaseLayout title={piece.title} location={location} >
      <header className="page-header small bg-secondary">
        <Container>
          <div className="header-content text-center">
            <h1 className="header-title bg-transparent">{piece.title}</h1>
          </div>
        </Container>
      </header>
      <section>
        <h4 className="section-title hidden">PROJECT SLIDER</h4>
        <Container>
          {_.size(carouselItems) === 1 && <img className="d-block w-100" src={carouselItems[0].src} alt={carouselItems[0].altText} />}
          {_.size(carouselItems) > 1 && <CarouselSlideshow items={carouselItems || []} />}
        </Container>
      </section>
      <section id="project-details" className="pt-0">
        <Container>
          <div className="section-w-image">
            <div className="row">
              <div className="col-lg-8 mt-3 mb-3">
                <h4 className="section-title mb-3">{piece.title}</h4>

                <Markdown source={piece.description} />
              </div>
              <div className="col-lg-4 mt-3 mb-3">
                <div className="project-info">
                  {
                    _.map(piece.metadata, ({ label, value }, i) => (
                      <div key={i} className="info">
                        <h6 className="mb-1">{label.toUpperCase()}</h6>
                        <Markdown source={value} />
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </BaseLayout>
  );
};

export default PieceTemplate;
